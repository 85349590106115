<template>
  <div>
    <el-dialog
      title="审核不通过原因"
      :visible.sync="show"
      width="30%"
      style="text-align: center;"
      @close="$emit('update:dialogVisible', false)"
    >
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="0">
        <el-form-item prop="remark">
          <el-input
            v-if="form"
            v-model="ruleForm.remark"
            type="textarea"
            :rows="7"
            placeholder=""
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import rules from '@/utils/rules.js'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: {
        remark: '',
        extendRemark: ''
      }
    },
    type: {
      type: [String, Number],
      default: 1
    }
  },
  data() {
    return {
      show: this.dialogVisible,
      ruleForm: {
        extendRemark: '',
        remark: ''
      },
      rules: {
        remark: [rules.req('请填写审核不通过原因')],
        extendRemark: [rules.req('请填写企业认证信息不通过原因')]
      }
    }
  },
  watch: {
    dialogVisible() {
      this.show = this.dialogVisible
      if (this.show) {
        this.ruleForm = {
          extendRemark: '',
          remark: '',
          id: this.form.id
        }
      }
    }
  },
  methods: {
    clear() {
      this.$emit('update:dialogVisible', false)
    },
    async submit() {
      await this.$refs['ruleForm'].validateField(['remark'], val => {
        if (val) {
          this.$message.error(val)
          return
        }
        this.up()
      })
    },
    async up() {
      const { data: res } = await this.$http.post(
        '/admin/Enterprise/enterpriseCaseCheck',
        {
          id: this.form.id,
          remark: this.ruleForm.remark,
          status: 2
        }
      )
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.$emit('update:dialogVisible', false)
        this.$emit('getlist')
      }
      return
    }
  }
}
</script>

<style lang="less" scoped>
h1 {
  font-size: 16px;
  // font-weight: bold;
  margin: 15px;
}
.el-dialog__header {
  text-align: center;
}
</style>
